import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LogoInvestor } from "../../Components/Images/Images";
import { Box, Button, Stack, Avatar } from "@mui/material";
import Launch from "../../InvestorOnly/LaunchPopUp/Launch";
import "./Header.css";

const HeaderInvestorOnly = () => {
  // state to hold app launch 
  const [launchClick, setLaunchClick] = useState(false);

  /**
   * Handle open launch app 
   */
  const handleClick1 = () => {
    setLaunchClick(true);
  };

  /**
   * Handle close launch app
   */
  const handleCancel = () => {
    setLaunchClick(false);
    window.scrollTo(0, 0);
  }

  // Function for Scroll to top
  function scrollTop() {
    document.documentElement.scrollTop = 0;
  }

  return (
    <>
      <Box className="main-header-investor-only">
        <Box className="container">
          <Box className="investor-only-item">
            <Box display={"flex"} alignItems={"center"}>
              <Box className="investor-only-logo">
                <Link to="/"  onClick={scrollTop}>
                  <Avatar variant="square" src={LogoInvestor} alt="Logo" />
                </Link>
              </Box>
              <Stack
                direction="row"
                spacing={4}
                className="headerLeft-menu"
                sx={{
                  display: {
                    xs: "flex",
                    md: "flex",
                    padding: "16px",
                    marginLeft: { xs: 0, md: "32px" },
                  },
                }}
              >
                {/* remove code before live */}
                <Box className="menu-item">
                  <Link to="/dic/login">
                    Decentralized Investment Committee
                  </Link>
                </Box>
              </Stack>
            </Box>
            <Button
              className="btn-rounded btn-golden"
              id="fade-button"
              onClick={handleClick1}
            >
              Launch App{" "}
            </Button>
          </Box>

          {launchClick ? (
            <Launch handleCancel={handleCancel} openModal={true} />
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
};

export default HeaderInvestorOnly;
